<template>
  <v-app style="position: relative">
    <d-navbar style="z-index: 151; position:fixed; width: 100%; top: 60px; background-color: #fff"/>
    <v-main style="margin-top:128px;">
      <slot></slot>
    </v-main>
    <v-bottom-navigation height="72"  color="primary" app>
      <v-btn height="72" class="pb-4" x-large link to="/orders">
        <span>{{ $t("basics.orders") }}</span>

        <v-icon>mdi-list-box-outline</v-icon>
      </v-btn>

      <v-btn height="72" class="pb-4" x-large link :to="{name: 'options'}">
        <span>{{ $t("basics.create_order") }}</span>

        <v-icon>mdi-playlist-edit</v-icon>
      </v-btn>

      <v-btn height="72" class="pb-4" x-large link @click="$router.push({name: 'index'})">
        <span>{{ $t("basics.settings") }}</span>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>

</template>

<script>

export default {
  name: "LayoutContent",
  components: {
    DNavbar: () => import('@/components/DNavbar.vue')
  },
  data() {
    return {
    }
  },
  mounted() {
    let params = this.$route.query.tgWebAppStartParam
    if (params)
      this.$router.push("/" + atob(params))

  },
  computed: {
    navigation() {
      return this.$route.meta.bottom_bar
    },
    init_data() {
      return JSON.stringify(atob(this.$route.query.tgWebAppStartParam))
    }
  },
  watch: {  }
}
</script>

<style scoped>
.menu-icon {
  position: fixed;
  top: 20px;
  z-index: 153;
  cursor: pointer;
  transition: 0.2s;
}

.menu-icon:hover {
  opacity: 0.8;
}

.menu-icon:active {
  opacity: 0.6;
}


</style>
